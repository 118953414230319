<script setup>
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';

import Layout from '@/components/common/Layout.vue';
import Announcements from '@/components/announcements/AnnouncementList.vue';
import AnnouncementFormModal from '@/components/announcements/AnnouncementFormModal.vue';
import useCurrentCompany from '@/composables/useCurrentCompany';
import announcementsService from '@/services/announcements.service';
import Pagination from '@/components/common/Pagination.vue';
import usePaginatedData from '@/composables/usePaginatedData';
import Button from '@/components/common/Button.vue';

const { t } = useI18n();
const toast = useToast();
const { currentCompanyId } = useCurrentCompany();
const {
  data: announcements,
  currentPage,
  totalPages,
  totalRecords,
  setData,
  resetData,
  goFirst,
  goLast,
  goNext,
  goPrevious,
} = usePaginatedData();

const loading = ref(false);
const isAddingAnnouncement = ref(false);
const currentAnnouncement = ref(null);
const showCreationModal = ref(false);

watch(currentAnnouncement, (value) => {
  if (!value) {
    showCreationModal.value = false;

    return;
  }

  showCreationModal.value = true;
});


    
const fetchAnnouncements = async (companyId, pageNumber = 1) => {
  try {
    loading.value = true;
    const response = await announcementsService.fetchAll(companyId, pageNumber);

    setData(response);
  } catch (err) {
    const [error] = err;

    toast.error(t(error));
    resetData();
  } finally {
    loading.value = false;
  }
};

watch(
  [currentCompanyId, currentPage],
  ([companyId, page]) => {
    if (!companyId) {
      return;
    }

    fetchAnnouncements(companyId, page);
  },
  { immediate: true },
);

const closeAnnouncementModal = () => {
  showCreationModal.value = false;
  currentAnnouncement.value = null;
};

const handleAnnouncementCreation = async (announcement) => {
  const {
    title,
    description,
    image,
  } = announcement;
  
  try {
    isAddingAnnouncement.value = true;
    const createdAnnouncement = await announcementsService.create(
      currentCompanyId.value,
      {
        title,
        description,
      },
    );

    // Everything went well, so we can add the associated image
    if (image?.blob) {
      await announcementsService.setImage(
        currentCompanyId.value,
        createdAnnouncement.id,
        image.blob,
        image.name,
      );
    }

    showCreationModal.value = false;
    goFirst();
    fetchAnnouncements(currentCompanyId.value);
    toast.success(t('pages.announcements.creationSuccess'));
  } catch (err) {
    const [error] = err;

    toast.error(t(error));
  } finally {
    isAddingAnnouncement.value = false;
  }
}
</script>

<template>
<Layout :loading="loading">
  <h1 class="tac">{{ t('pages.announcements.title') }}</h1>

  <Button class="tac mt-2" small @buttonClick="showCreationModal = true">
    {{ t('pages.announcements.creationButton') }}
  </Button>

  <AnnouncementFormModal
    :visible="showCreationModal"
    :announcement="currentAnnouncement"
    :isAdding="isAddingAnnouncement"
    @close="closeAnnouncementModal"
    @create="handleAnnouncementCreation"
  />
  
  <Announcements
    class="mt-4"
    :announcements="announcements"
    :totalRecords="totalRecords"
    @announcementClick="currentAnnouncement = $event"
  />

  <Pagination
    class="mt-2"
    :current="currentPage"
    :total="totalPages"
    @go-first="goFirst"
    @go-last="goLast"
    @go-next="goNext"
    @go-previous="goPrevious"
  />
</Layout>
</template>
