<script setup>
import { defineProps, defineEmits, ref, watch, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Modal from '@/components/common/Modal.vue';
import FormGroup from '@/components/common/FormGroup.vue';
import Button from '@/components/common/Button.vue';
import ImageCropper from '@/components/common/ImageCropper.vue';

const initialFormData = {
  title: '',
  description: '',
  image: null,
};

const editorConfiguration = {
  toolbar: [
    'bold',
    'italic',
    'numberedList',
    // 'bulletedList',
    '|',
    'link',
    '|',
    'undo',
    'redo',
  ],
};

const props = defineProps({
  visible: { type: Boolean, required: true },
  announcement: { type: Object, required: false },
  isAdding: { type: Boolean, required: false },
});

defineEmits(['create', 'close']);

const { visible, announcement } = toRefs(props);

const { t } = useI18n();

const formData = ref({ ...initialFormData });
const errors = ref([]);
const fileInputRef = ref(null);
const imgSrc = ref(null);
const fileName = ref('blob.jpeg');
const showImageCropper = ref(false);
const imagePreviewSrc = ref(null);

watch(
  announcement,
  (value) => {
    if (!value) {
      formData.value = { ...initialFormData };
      imagePreviewSrc.value = null;
      
      return;
    }
    
    formData.value = value;
    imagePreviewSrc.value = value.image || null;
  },
);

watch(visible, (value) => {
  if (value) {
    return;
  }

  formData.value = { ...initialFormData };
  imagePreviewSrc.value = null;
})

const loadImage = (event) => {
  const file = event.target.files[0];

  errors.value = [];

  const fileNameRegExp = new RegExp(/(\.png|jpeg|jpg|svg)$/, 'i');
  fileName.value = file.name;

  if (!fileNameRegExp.test(file.name)) {
    errors.value = [
      ...errors.value,
      'Le fichier doit être une image du format JPEG/JPG/PNG/SVG',
    ];

    return;
  }

  const reader = new FileReader();
  reader.onload = (e) => {
    imgSrc.value = e.target.result;
  };

  reader.readAsDataURL(file);
  showImageCropper.value = true;
};


const handleImageSaving = (result) => {
  if (!showImageCropper.value) {
    return;
  }

  const { image, errors: errorResult } = result;

  if (errorResult?.length) {
    errors.value = [
      ...errors.value,
      ...errorResult,
    ];

    showImageCropper.value = false;

    return;
  }

  imagePreviewSrc.value = image.mediaUrl;
  formData.value.image = { blob: image.blob, name: fileName.value };

  showImageCropper.value = false;
};
</script>

<template>
<Modal
  :title="t(`components.announcementFormModal.${announcement ? 'details': 'add'}Title`)"
  :isDisplayed="visible"
  :isFreezed="isAdding"
  :contentClassname="$style.modalContent"
  @closeModal="$emit('close')"
>
  <FormGroup
    name="title"
    :disabled="!!announcement"
    :label="t('components.announcementFormModal.fields.title')"
    v-model="formData.title"
  />

  <div :class="['mt-2', { editorDisabled: !!announcement }]">
    <label for="description">
      {{ t('components.announcementFormModal.fields.description') }}
    </label>
    <ckeditor
      id="description"
      :disabled="!!announcement"
      :editor="ClassicEditor"
      :config="editorConfiguration"
      v-model="formData.description"
    />
  </div>

  <div class="mt-2">
    <label for="image" class="block">{{ t('components.announcementFormModal.fields.image') }}</label>
    <input
      id="image"
      type="file"
      accept="image/png, image/jpeg, image/jpg"
      ref="fileInputRef"
      class="hidden"
      @change="loadImage"
    />

    <Button
      v-if="!imagePreviewSrc && !announcement"
      class="mt-1"
      extra-small
      @buttonClick="fileInputRef.click()"
    >
      {{ t('components.announcementFormModal.selectImage') }}
    </Button>
    <p v-else-if="!!announcement && !announcement.image">
      {{ t('components.announcementFormModal.noImage') }}
    </p>
    <img v-else class="mt-2" :src="imagePreviewSrc" alt="">
  </div>

  <Modal
    :title="t('components.announcementFormModal.cropperModalTitle')" 
    :isDisplayed="showImageCropper"
    @closeModal="showImageCropper = false"
  >
    <ImageCropper
      :image-source="imgSrc"
      @change="handleImageSaving"
    />
  </Modal>

  <div v-if="!announcement" :class="$style.buttons">
      <Button small isDanger @buttonClick="$emit('close')">
        {{ t('common.cancel') }}
      </Button>
    <Button small :isLoading="isAdding" @buttonClick="$emit('create', formData)">
      {{ t('common.confirm') }}
      </Button>
  </div>
  <Button v-else class="tar mt-2" small @buttonClick="$emit('close')">
    {{ t('common.close') }}
  </Button>
</Modal>
</template>

<style lang="scss">
/* Override ckeditor content style */
.editorDisabled {
  --ck-color-base-background: #{$light-gray};

  p {
    color: $dark-gray;
  }
}

.ck.ck-content {
  min-height: 15rem;
}
</style>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.modalContent {
  width: 100%;

  @include xs {
    width: 50rem;
  }
}

.buttons {
  margin-top: 3rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include spacing-children('horizontal', 1rem);
}
</style>
