<script setup>
import { defineProps, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';

import placeholderImage from '@/assets/images/empty_announcements.svg';

import AnnouncementCard from './AnnouncementCard.vue';
import Empty from '../common/Empty.vue';

defineProps({
  announcements: { type: Array, required: true },
  totalRecords: { type: Number, required: true },
});

defineEmits(['announcementClick']);

const { t } = useI18n();
</script>

<template>
  <div v-if="!!totalRecords" :class="$style.container">
    <AnnouncementCard
      v-for="announcement in announcements"
      :key="announcement.id"
      :announcement="announcement"
      @announcementClick="$emit('announcementClick', announcement)"
    />
  </div>
  <template v-else>
    <Empty class="mt-2" :image="placeholderImage">{{ t('components.announcementList.empty') }}</Empty>
  </template>
</template>

<style lang="scss" module>
.container {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 30rem);
  gap: 2rem;

  margin: 2rem auto 0;
}
</style>
