import config from '@/config';

import httpService from '@/services/http.service';
import apiErrorUtils from '@/utils/apiErrors.utils';

const BASE_URL = config.lowcoApiUrl;

const mapError = (error) => {
  switch (error.code) {
    default:
      return 'errorMessages.general';
  }
};

const throwError = apiErrorUtils.makeThrowError(mapError);

const fetchAll = (companyId, pageNumber = 1, pageSize = 15) => httpService
  .get(
    `${BASE_URL}/companies/${companyId}/announcements?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    null,
    { paginated: true },
  ).catch(throwError);

const create = (companyId, announcement) => httpService
  .post(`${BASE_URL}/companies/${companyId}/announcements`, announcement)
  .catch(throwError);

const setImage = (companyId, announcementId, image, fileName) => {
  const formData = new FormData();

  formData.append('Files', image, fileName);

  return httpService
    .postFormData(`${BASE_URL}/companies/${companyId}/announcements/${announcementId}/image`, formData)
    .catch(throwError);
}

export default {
  fetchAll,
  setImage,
  create,
};
