import {
  format,
  isDate,
  parseISO,
} from 'date-fns';
import { fr } from 'date-fns/locale';

const parse = (date) => {
  if (!date) {
    return null;
  }

  if (isDate(date)) {
    return date;
  }

  return parseISO(date);
}

const createFormatDate = (dateFormat) => (date) => {
  const parsedDate = parse(date);

  if (!parsedDate) {
    return null;
  }

  return format(parsedDate, dateFormat, { locale: fr });
}

const formatDate = createFormatDate('Pp');

const formatToSimpleDate = createFormatDate('P')

export default {
  parse,
  formatDate,
  formatToSimpleDate,
};
