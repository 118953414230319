import { ref } from 'vue';

const usePaginatedData = () => {
  const data = ref([]);
  const currentPage = ref(1);
  const totalPages = ref(1);
  const totalRecords = ref(0);

  const setData = (paginationData) => {
    const {
      data: pagedData,
      totalPages: pages,
      totalRecords: recordsCount,
    } = paginationData;

    data.value = pagedData;
    totalPages.value = pages;
    totalRecords.value = recordsCount;
  };

  const resetData = () => setData({
    data: [],
    currentPage: 1,
    totalPages: 1,
    totalRecords: 0,
  });

  const goNext = () => {
    currentPage.value = Math.min(currentPage.value + 1, totalPages.value);
  };
  
  const goPrevious = () => {
    currentPage.value = Math.max(currentPage.value - 1, 0);
  };

  const goFirst = () => {
    currentPage.value = 1;
  };

  const goLast = () => {
    currentPage.value = totalPages.value;
  };

  return {
    totalRecords,
    totalPages,
    currentPage,
    data,
    goFirst,
    goLast,
    goPrevious,
    goNext,
    setData,
    resetData,
  };
};

export default usePaginatedData;
